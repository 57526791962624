import React, { useEffect, useRef } from "react";
import { Link, useHref } from "react-router-dom";
import Style from "./HomeAboutUs.module.scss";
import CharectorSplit from "../../logic/CharectorSplit";
import { useState } from "react";
import Assets from "../Layouts/Assets";
import CountUp from "react-countup";

const HomeAboutUs = ({ aboutData }) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction);
    if (containerRef.current) observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef]);

  return (
    <div className={`${Style.aboutusec} ${Style.loaded}`}>
      <span className={Style.circleshape_one}></span>
      <span className={Style.ovalshape}></span>

      <div className="container">
        <div className={Style.wrapper}>
          <header className={`sectitle ${Style.sectitle} `}>
            <h2 className={`anim`}>{aboutData?.details["home-about-us-main-title"]}</h2>
          </header>
          <div className={Style.shortcnt}>
            <p className={`anim`}>{aboutData?.details["home-about-us-main-description"]}</p>
          </div>

          <div className={Style.countsec} ref={containerRef}>
            {aboutData?.details["home-about-us-status-updates"] &&
              aboutData?.details["home-about-us-status-updates"]?.length > 0 &&
              aboutData?.details["home-about-us-status-updates"]?.map((value, i) => {
                return (
                  <div key={i} className={"aboutUs-el"}>
                    <figure className={Style.itemtImg}>
                      <img src={value?.icon?.url} alt="" />
                    </figure>
                    {isVisible && (
                      <div className={`${Style.count}`}>
                        {/* <CharectorSplit>{value?.count}</CharectorSplit> */}

                        <CountUp
                          key={i}
                          start={value?.count?.start}
                          end={value?.count?.value}
                          duration={3}
                          delay={0}
                          formattingFn={(values) =>
                            values?.toLocaleString("en-US", { useGrouping: false })
                          }
                        />
                      </div>
                    )}
                    <header className={Style.itemtitle}>{value?.Title}</header>
                  </div>
                );
              })}
          </div>

          {aboutData?.details?.["home-about-us-button-url"]?.indexOf("://") == -1 ? (
            <Link
              to={`${aboutData?.details?.["home-about-us-button-url"]}`}
              className={`btn btn-brdr ${Style.aboutbtn}`}
            >
              <span>{aboutData?.details?.["home-about-us-button-text"]}</span>
            </Link>
          ) : (
            <a
              href={aboutData?.details?.["home-about-us-button-url"]}
              className={`btn btn-brdr ${Style.aboutbtn}`}
              target="_blank"
            >
              <span>{aboutData?.details?.["home-about-us-button-text"]}</span>
            </a>
          )}
        </div>
      </div>
      <span></span>
    </div>
  );
};

export default HomeAboutUs;
