import React, { useState, useRef, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleRecaptcha = ({ onChange, captchaReset }) => {
  const captcharef = useRef(null);

  const siteKey = "6LfPMAgeAAAAAIgGQJomD4eNeB05jHVau-2pHhmK"; //webc key

  // const siteKey = "6LeR4nQlAAAAAKyVzdX0Dsyzx5hbaNxVvlvJeTlp"; //live website

  useEffect(() => {
    if (captchaReset) {
      captcharef.current.reset();
    }
  }, [captchaReset]);

  return (
    <>
      <ReCAPTCHA ref={captcharef} sitekey={siteKey} theme={"light"} onChange={onChange} />
    </>
  );
};

export default GoogleRecaptcha;
